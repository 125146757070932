import React from 'react';
import { IconV2, LayoutBox, Headline } from '@bamboohr/fabric';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import LockOutInterstitial from '../LockOutInterstitial';

const FraudLockOut = ({ isPotentialFraud }) => {
	const emailAddress = isPotentialFraud ? 'success@bamboohr.com' : 'support@bamboohr.com';

	return (
		<LockOutInterstitial>
			<LayoutBox marginBottom={1.5} marginTop={3.5}>
				{isPotentialFraud
					? <IconV2 color='primary-strong' name='lock-regular' size={60}/>
					: <IconV2 color='primary-strong' name='wrench-regular' size={60}/>
				}
			</LayoutBox>
			<Headline color='neutral-strong' justify='center' size='small'>{ $.__(`We're working on it.`) }</Headline>
			<LayoutBox color='primary-strong' marginBottom={2} marginTop={1.5}>
				<Message
					link={ ExternalLink(`mailto:${ emailAddress }`) }
					text={ $.__('Sorry, this site is temporarily unavailable. \n Questions? [Email us].') }
				/>
			</LayoutBox>
        </LockOutInterstitial>
    );
};

FraudLockOut.defaultProps = {
	isPotentialFraud: false,
};

export default FraudLockOut;
