import { setThemeColor, ThemeShades } from '@bamboohr/fabric';

const THEME = document.body.getAttribute('ba-theme');

export function setupTheme() {
	if (typeof THEME === 'string' && THEME.startsWith('{')) {
		const parsedTheme: ThemeShades = JSON.parse(THEME) as ThemeShades;
		setThemeColor(parsedTheme);
	}
}

