import React, { ReactElement, useState } from 'react';
import { TextButton, Flex, LayoutBox, StyledBoxV2 } from '@bamboohr/fabric';

export function LoginPassword({ showForgotPassword = true, inputId = 'password' }): ReactElement {
	const [passwordType, setPasswordType] = useState('password');
	const [passwordText, setPasswordText] = useState($.__('Show Password'));

	const updatePasswordType = () => {
		setPasswordType(passwordType === 'password' ? 'text' : 'password');
		setPasswordText(passwordText === $.__('Show Password') ? $.__('Hide Password') : $.__('Show Password'));
	};

	return (
		<LayoutBox marginBottom={showForgotPassword ? 4 : ''}>
			<div className='fab-InputWrapper'>
				<input
					autoComplete='off'
					className="fab-TextInput fab-TextInput--withNub fab-TextInput--width9 fab-typography--medium"
					id={inputId}
					name='password'
					placeholder={$.__('Password')}
					tabIndex={window.location.pathname.includes('self_onboarding') ? 0 : 4}
					type={passwordType}
				/>
				<span className='fab-TextInputNub'>
					<span className='fab-TextInputNub__icon'>
						<ba-icon encore-name="key-regular" encore-size="16" name='fab-key-18x18'></ba-icon>
					</span>
				</span>
			</div>
			<StyledBoxV2 marginTop={0.5}>
				<Flex alignItems='center' justifyContent={showForgotPassword ? 'space-between' : 'flex-end'}>
					{showForgotPassword && (
						<button
							className="fab-TextButton fab-TextButton--muted fab-TextButton--small ffront forgot"
							type="button"
						>
							{$.__('Forgot Password?')}
						</button>
					)}
					<TextButton color="secondary" onClick={updatePasswordType} size="small" type="button">
						{passwordText}
					</TextButton>
				</Flex>
			</StyledBoxV2>
		</LayoutBox>
	);
}
export default LoginPassword;
